import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Gallery from '../components/Gallery'

export const ImmaginiTemplate = ({ gallery }) => {
  return (
    <section className="section section--gradient">
      <Gallery gallery={gallery} />
    </section>
  )
}

ImmaginiTemplate.propTypes = {
  gallery: PropTypes.array.isRequired,
}

const ImmaginiPage = ({ data }) => {
  const { markdownRemark: post } = data
  return (
    <Layout>
      <ImmaginiTemplate
        gallery={post.frontmatter.gallery}
      />
    </Layout>
  )
}

ImmaginiPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ImmaginiPage

export const immaginiPageQuery = graphql`
  query ImmaginiPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        gallery {
          immagine {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
